import { useLingui } from '@lingui/react';
import { useMemo } from 'react';

import { notesEN } from './en-GB';
import { notesNL } from './nl-NL';

const useReleases = () => {
  const { i18n } = useLingui();
  const locale = i18n.locale;

  const releases = useMemo(() => {
    if (locale === 'en-gb') return notesEN;
    return notesNL;
  }, [locale]);

  return releases;
};

export default useReleases;
