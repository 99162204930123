import { Onboarding } from '@elseu/sdu-titan';
import React from 'react';

import { useOnboarding } from './OnboardingProvider';

export const OnboardingModal = () => {
  const { steps, isShownModal, onToggleModal } = useOnboarding();

  if (!steps?.length) return null;

  return (
    <Onboarding
      defaultCurrentPage={1}
      isDefaultShown={isShownModal}
      isShown={isShownModal}
      stepsConfiguration={steps}
      testId="onboardingModal"
      onToggle={onToggleModal}
    />
  );
};
