import { StarFilledIcon, StatusMessage } from '@elseu/sdu-titan';
import { Trans } from '@lingui/macro';
import { config } from 'config';
import React from 'react';

import { useOnboarding } from './OnboardingProvider';

const OnboardingMessage = () => {
  const { isShownMessage, onToggleModal } = useOnboarding();

  if (!isShownMessage) return null;

  return (
    <StatusMessage
      alignment="center"
      icon={<StarFilledIcon />}
      isShown={isShownMessage}
      link={{
        onClick: () => onToggleModal(true),
      }}
      type="information"
    >
      <Trans>
        {config.brandName} is verbeterd met nieuwe functionaliteiten, bekijk wat er nieuw is
      </Trans>
    </StatusMessage>
  );
};

export { OnboardingMessage };
