import { useMutation } from '@apollo/client';
import type { Locale } from '@elseu/sdu-evidend-graphql';
import { useLingui } from '@lingui/react';
import { UPDATE_ACCOUNT } from 'graphql/queries/account';
import { localeToIso } from 'helpers/locale';
import { useAccount } from 'hooks/useAccount';
import { useCallback } from 'react';
import type { UpdateAccount, UpdateAccountVariables } from 'types/graphql/UpdateAccount';

export const useSetLocale = () => {
  const { i18n } = useLingui();
  const account = useAccount();
  const [setAccount] = useMutation<UpdateAccount, UpdateAccountVariables>(UPDATE_ACCOUNT);

  return useCallback(
    (locale: Locale) => {
      // Only update language setting for current user if user is logged in.
      if (account) {
        setAccount({
          variables: {
            account: {
              locale,
            },
          },
          onCompleted: () => {
            i18n.activate(localeToIso(locale));
          },
        });
      }
    },
    [i18n, setAccount, account],
  );
};
