import { useMutation } from '@apollo/client';
import { t } from '@lingui/macro';
import { InputField } from 'components/FormFields/InputField';
import { SidebarFormContainer, SidebarFormContent } from 'components/SidebarForm/SidebarForm';
import { SAVE_JSON_REGISTER_MUTATION } from 'graphql/queries/management';
import { useGlobalError } from 'hooks/useGlobalError';
import type {
  SaveJsonRegisterMutation,
  SaveJsonRegisterMutationVariables,
} from 'types/graphql/SaveJsonRegisterMutation';
import * as yup from 'yup';

const getJsonFormSchema = () => {
  return yup.object({
    registerJson: yup.string().trim().nullable().required(),
  });
};

export const jsonFormSchema = getJsonFormSchema();

export type JsonFormValues = yup.InferType<typeof jsonFormSchema>;

interface JsonSidebarFormProps {
  isSidebarShown: boolean;
  onSidebarClose: () => any;
  onUpdate?: (registerId: string) => any;
}
export const JsonSidebarForm = ({ isSidebarShown, ...props }: JsonSidebarFormProps) => {
  return (
    <SidebarFormContainer isDrawerShown={isSidebarShown}>
      <JsonSidebarFormContent {...props} />
    </SidebarFormContainer>
  );
};

const JsonSidebarFormContent = ({
  onSidebarClose,
  onUpdate,
}: Omit<JsonSidebarFormProps, 'isSidebarShown'>) => {
  const [, setGlobalError] = useGlobalError();
  const [saveJsonRegister] = useMutation<
    SaveJsonRegisterMutation,
    SaveJsonRegisterMutationVariables
  >(SAVE_JSON_REGISTER_MUTATION);

  return (
    <SidebarFormContent
      form={
        <>
          <InputField
            label={t`JSON`}
            name="registerJson"
            placeholder={t`JSON`}
            rows="4"
            type="textarea"
          />
        </>
      }
      formSchema={jsonFormSchema}
      header={t`Register importeren`}
      setDrawerClosed={onSidebarClose}
      subtitle={t`Je kunt hier een register importeren met JSON.`}
      onSubmit={async ({ registerJson }: JsonFormValues) => {
        // Clear any previous error.
        setGlobalError(undefined);

        saveJsonRegister({
          variables: {
            registerJson,
          },
          onCompleted: ({ saveJsonRegister }) => {
            setGlobalError(t`Het register is aangemaakt.`);
            onSidebarClose();
            onUpdate?.(saveJsonRegister.register.id);
          },
          onError: () => setGlobalError(t`Er is een fout opgetreden.`),
        });
      }}
    />
  );
};
