import { gql } from '@apollo/client';

import { MUTATION_FRAGMENT, NAME_FRAGMENT } from '../fragments';

const MANAGEMENT_USER_FRAGMENT = gql`
  fragment ManagementUserFields on ManagementUser {
    user {
      id
      email
      name {
        ...NameFields
      }
    }
    admin
    group {
      id
    }
    temporaryAccessExpiration
  }
  ${NAME_FRAGMENT}
`;

const MANAGEMENT_GROUP_FRAGMENT = gql`
  fragment ManagementGroupFields on ManagementGroup {
    group {
      id
      name
    }
    remarks
    buyerIds
    test
    notary
    temporaryAccessExpiration
  }
`;

export const DELETE_TEST_DATA_QUERY = gql`
  query DeleteTestData {
    deleteTestData
  }
`;

export const USERS_QUERY = gql`
  query Users {
    users {
      ...ManagementUserFields
    }
  }
  ${MANAGEMENT_USER_FRAGMENT}
`;

export const GROUPS_QUERY = gql`
  query Groups {
    groups {
      ...ManagementGroupFields
    }
  }
  ${MANAGEMENT_GROUP_FRAGMENT}
`;

export const MUTATION_SUMMARY_QUERY = gql`
  query MutationSummary($groupId: ID) {
    mutationSummary(groupId: $groupId) {
      mutation {
        ...MutationFields
        register {
          legalEntity {
            name
          }
        }
      }
      group {
        id
        name
      }
    }
  }
  ${MUTATION_FRAGMENT}
`;

export const CREATE_GROUP = gql`
  mutation CreateGroup($group: GroupInput!) {
    createGroup(group: $group) {
      ...ManagementGroupFields
    }
  }
  ${MANAGEMENT_GROUP_FRAGMENT}
`;

export const UPDATE_GROUP = gql`
  mutation UpdateGroup($groupId: ID!, $group: GroupInput!) {
    updateGroup(groupId: $groupId, group: $group) {
      ...ManagementGroupFields
    }
  }
  ${MANAGEMENT_GROUP_FRAGMENT}
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($userId: ID!, $user: UserInput!) {
    updateUser(userId: $userId, user: $user) {
      ...ManagementUserFields
    }
  }
  ${MANAGEMENT_USER_FRAGMENT}
`;

export const JSON_REGISTER_QUERY = gql`
  query JsonRegisterQuery($registerId: ID!) {
    jsonRegister(registerId: $registerId)
  }
`;

export const DELETE_REGISTER_MUTATION = gql`
  mutation DeleteRegisterMutation($registerId: ID!) {
    deleteRegister(registerId: $registerId)
  }
`;

export const DEEP_DELETE_REGISTER_MUTATION = gql`
  mutation DeepDeleteRegisterMutation($registerId: ID!) {
    deepDeleteRegister(registerId: $registerId)
  }
`;

export const SAVE_JSON_REGISTER_MUTATION = gql`
  mutation SaveJsonRegisterMutation($registerJson: String!) {
    saveJsonRegister(registerJson: $registerJson) {
      register {
        id
      }
    }
  }
`;
