import type { OnboardingStepProps } from '@elseu/sdu-titan';
import { Image, StyledHTML } from '@elseu/sdu-titan';
import { formatISO, isAfter, isValid, parseISO } from 'date-fns';
import { formatDate } from 'helpers/formatShares';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import { renderToString } from 'react-dom/server';
import { useCookie } from 'react-use';
import useReleases from 'releases/useReleases';

interface IOnboardingContext {
  isShownModal: boolean;
  isShownMessage: boolean;
  steps?: OnboardingStepProps[];
  onToggleModal: (isShown: boolean) => void;
}

export const OnboardingContext = React.createContext<IOnboardingContext | undefined>({
  isShownModal: false,
  isShownMessage: false,
} as IOnboardingContext);

export const useOnboarding = () => {
  const context = React.useContext(OnboardingContext);
  if (context === undefined) {
    throw new Error('useOnboarding must be used within a OnboardingProviderProvider');
  }
  return context;
};

export const OnboardingProvider = ({ children }: { children: React.ReactNode }) => {
  const [isShownModal, setShowModal] = useState(false);
  const [isShownMessage, setShowMessage] = useState(false);

  // Get last seen date from cookie (might be empty or invalid).
  const [lastSeen, setLastSeen] = useCookie('onboarding');

  // Verify if last seen date is valid.
  const isLastSeenValid = useMemo(() => {
    if (!lastSeen) return false;

    const date = parseISO(lastSeen);
    return isValid(date);
  }, [lastSeen]);

  // Helper function to set the last seen date to now.
  const updateLastSeen = useCallback(() => {
    setLastSeen(formatISO(new Date()));
  }, [setLastSeen]);

  // Automatically set the last seen date to now if it is invalid or missing.
  // This clears invalid values, and sets a value to compare against when the client returns.
  useEffect(() => {
    if (!isLastSeenValid) {
      updateLastSeen();
    }
  }, [isLastSeenValid, updateLastSeen]);

  // Determine release notes based on current locale.
  const releases = useReleases();

  const steps: OnboardingStepProps[] = useMemo(() => {
    // No steps while last seen is invalid.
    if (!lastSeen || !isLastSeenValid) return [];

    // Only return release notes that have a date after the last seen date.
    const lastSeenDate = parseISO(lastSeen);
    return releases
      .filter(({ date }) => isAfter(date, lastSeenDate))
      .map(
        ({ date, title, media, content }) =>
          ({
            title,
            label: formatDate(date),
            content: renderToString(content),
            media:
              typeof media === 'string' ? (
                <Image alt={title} objectFit="contain" src={media as any} width="100%" />
              ) : (
                <StyledHTML content={renderToString(media as any)} spaceAfter={2} />
              ),
          } as OnboardingStepProps),
      );
  }, [releases, isLastSeenValid, lastSeen]);

  // Helper to toggle the onboarding modal, and reset the last seen date when it is closed.
  const onToggleModal = useCallback(
    (isShown: boolean) => {
      setShowModal(isShown);

      // When closing the modal, set the cookie to the current date and hide the message
      if (!isShown) {
        setLastSeen(formatISO(new Date()));
        setShowMessage(false);
      }
    },
    [setLastSeen],
  );

  // Start showing the onboarding message if there are new release notes.
  useEffect(() => {
    if (!steps.length) return;

    setShowMessage(true);
  }, [steps]);

  const context = useMemo(
    () => ({
      isShownModal,
      isShownMessage,
      steps,
      onToggleModal,
    }),
    [isShownModal, isShownMessage, steps, onToggleModal],
  );

  return <OnboardingContext.Provider value={context}>{children}</OnboardingContext.Provider>;
};
