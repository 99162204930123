import { Locale } from '@elseu/sdu-evidend-graphql';

export const localeToIso = (locale: Locale) => {
  if (locale === Locale.ENGLISH_BRITISH) {
    return 'en-gb';
  }
  return 'nl';
};

export const isoToLocale = (iso: string): Locale => {
  if (iso === 'en-gb') {
    return Locale.ENGLISH_BRITISH;
  }
  return Locale.DUTCH;
};
