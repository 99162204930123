import { parseISO } from 'date-fns';
import type { ReleaseNote } from 'releases';

import image_20220630_correct_nl from './images/2022-06-30/correct-nl.png';
import image_20220630_group_nl from './images/2022-06-30/group-nl.png';
import image_20220630_preview_nl from './images/2022-06-30/preview-nl.png';
import image_20220725_export_nl from './images/2022-07-25/export-nl.png';
import image_20220725_ownership_nl from './images/2022-07-25/ownership-nl.png';
import image_20220725_whatsnew_nl from './images/2022-07-25/whatsnew-nl.png';
import image_default from './images/default.jpg';

/**
 * Always put new release notes at the bottom of the array (`date` field ascending).
 * Release notes with the same date should be ordered by importance, with most important first.
 *
 * Don't forget to add the English release notes too! No need to add t`` or <Trans> tags.
 */
export const notesNL: ReleaseNote[] = [
  /**
   * Release 2022-06-30
   */
  {
    date: parseISO('2022-06-30T12:00:00+0200'),
    title: 'Overzichten Aandelenkapitaal en Aandelenverdeling',
    content: (
      <div>
        <p>
          De overzichten op de tabs voor het aandelenkapitaal en de aandelenverdeling zijn
          vernieuwd. De verschillende categorieën kunnen nog steeds geopend worden door erop te
          klikken. Nieuw is dat het nu ook mogelijk is om door alle categorieën tegelijk te zoeken
          met de zoekbalk rechtsboven de tabel.
        </p>

        <p>
          Op de tab met de aandelenverdeling is het ook mogelijk om de indeling te wijzigen.
          Standaard is deze nu ingedeeld per aandeelhouder, maar het is mogelijk om in te delen op
          soort aandelen.
        </p>
      </div>
    ),
    media: image_20220630_group_nl.src,
  },
  {
    date: parseISO('2022-06-30T12:00:00+0200'),
    title: 'Nieuw: Corrigeren afgeronde mutatie',
    content: (
      <div>
        <p>
          Het was al mogelijk om de laatst afgeronde mutatie ongedaan te maken in Evidend om
          eventuele vergissingen te kunnen corrigeren. In het geval van een zeer grote mutatie
          betekende dit echter wel dat een kleine vergissing betekende dat de hele mutatie opnieuw
          verwerkt moest worden. Vanaf nu is het daarom mogelijk om de laatst afgeronde mutatie te
          corrigeren.
        </p>

        <p>
          Door te kiezen voor corrigeren open je de mutatie opnieuw en kun je de ingevoerde gegevens
          aanpassen. Het corrigeren van een mutatie brengt geen extra kosten met zich mee. Deze
          mogelijkheid is beschikbaar voor alle mutaties die na 20 mei zijn gemaakt.
        </p>
      </div>
    ),
    media: image_20220630_correct_nl.src,
  },
  {
    date: parseISO('2022-06-30T12:00:00+0200'),
    title: 'Nieuw: Voorbeeldweergave',
    content: (
      <div>
        <p>
          Wanneer er veel documenten aan een register zijn gekoppeld kan het lastig zijn om het
          juiste document terug te vinden. Om dit gemakkelijker te maken hebben we een
          voorbeeldweergave toegevoegd voor pdf documenten, zodat het niet meer nodig is om een pdf
          document te downloaden om de inhoud te kunnen bekijken.
        </p>

        <p>Via de drie puntjes aan de rechterkant kun je de voorbeeldweergave bereiken.</p>
      </div>
    ),
    media: image_20220630_preview_nl.src,
  },
  {
    date: parseISO('2022-06-30T12:00:00+0200'),
    title: 'Kleine verbeteringen en bugfixes',
    content: (
      <div>
        <ul>
          <li>
            Bij het downloaden van een grote vennootschapsstructuur werden de teksten soms
            onleesbaar. De resolutie van de afbeelding wordt nu aangepast aan de omvang van de
            structuur, zodat deze altijd goed leesbaar blijft.
          </li>
          <li>
            In de diagramweergave van de aandelenverdeling worden de percentages nu getoond met 2
            decimalen achter de komma net als in de vennootschapsstructuur.
          </li>
          <li>Het is nu mogelijk om een pdf export te maken van een certificaathoudersregister</li>
          <li>Het is nu mogelijk om een eerder geüpload document te verwijderen.</li>
          <li>
            Bug gerepareerd die ervoor zorgde dat adresgegevens van natuurlijke personen niet goed
            werden opgeslagen.
          </li>
          <li>
            Bug gerepareerd waardoor het niet mogelijk was om gegevens van aandeelhouders te
            wijzigen.
          </li>
        </ul>
      </div>
    ),
    media: image_default.src,
  },

  /**
   * Release 2022-07-25
   */
  {
    date: parseISO('2022-07-25T12:00:00+0200'),
    title: 'Wat is er nieuw',
    content: (
      <div>
        <p>
          Vanaf deze versie worden de laatste aanpassingen in Evidend weergegeven onder de
          menufunctie “Wat is er nieuw?”. Deze functie vind je in het menu aan de rechterzijde als
          je klikt op het rondje met je initialen.
        </p>
        <p>De laatste releasenote is onder de nieuwe menufunctie dus altijd terug te vinden.</p>
      </div>
    ),
    media: image_20220725_whatsnew_nl.src,
  },
  {
    date: parseISO('2022-07-25T12:00:00+0200'),
    title: 'Nieuw: Eigen kenmerk voor natuurlijke personen',
    content: (
      <div>
        <p>
          Het is nu mogelijk om een eigen kenmerk mee te geven aan de gegevens van een natuurlijk
          persoon. Dit is een vrij veld en niet verplicht. Als het veld wel wordt ingevuld dan
          worden de gegevens ook getoond bij de natuurlijke persoon. Op de gegevens uit dit veld kan
          ook worden gezocht met de algemene zoekfunctie in Evidend.
        </p>
        <p>
          Dit veld is bijvoorbeeld nuttig in certificaathouders registers voor familiebedrijven
          waarbij de naam alleen niet voldoende onderscheidend is. Het kan ook gebruikt worden om
          een kenmerk uit een ander systeem op te nemen.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2022-07-25T12:00:00+0200'),
    title: 'Weergave aandelenverdeling',
    content: (
      <div>
        <p>
          In de diagram weergave van de aandelenverdeling kun je nu filteren op de soorten aandelen,
          waarbij ook de aandelen met stemrecht of dividendrecht gefilterd kan worden. Ook is het nu
          mogelijk om de verdeling op basis van nominale waarde weer te geven. Let wel dat als
          gefilterd wordt op aandelen met stemrecht/dividendrecht, de aandelenverdeling thans toont
          wie de eigenaar van die aandelen is. Niet wie daadwerkelijk het stemrecht/dividendrecht
          heeft, mocht deze overgedragen zijn.
        </p>
      </div>
    ),
    media: image_20220725_ownership_nl.src,
  },
  {
    date: parseISO('2022-07-25T12:00:00+0200'),
    title: 'Laadtijden voor grote workflows',
    content: (
      <div>
        <p>
          Als meer dan ca 100 aandeelhouders in een mutatie werden opgevoerd, dan werd Evidend een
          stuk trager. We hebben verschillende verbeteringen doorgevoerd, waardoor de snelheid ook
          op peil blijft bij deze grote mutaties.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2022-07-25T12:00:00+0200'),
    title: 'Aanpassingen in het export document (PDF)',
    content: (
      <div>
        <p>In het export document (PDF) zijn de volgende wijzigingen/verbeteringen aangebracht:</p>
        <ul>
          <li>
            Van een aandeelhoudersregister staan de soorten aandelen nu correct gesorteerd op de
            naam van het aandeel en daarbinnen op eventuele letters en de nominale waarde.
          </li>
          <li>De nominale waarde wordt niet meer afgerond. Dus 0,00001 wordt niet meer 0,00.</li>
          <li>
            De pagina’s voor maatschappelijk kapitaal en wel of niet volgestort kapitaal worden nu
            altijd getoond. Ook als het betreffende overzicht leeg is. Dit geeft de situatie
            duidelijker weer dan wanneer deze pagina’s worden weggelaten.
          </li>
          <li>
            Een reeks van 1 aandeel wordt niet langer aangeduid met ‘1 tot 1’, maar alleen met het
            nummer van het aandeel. Dus ‘1-1’ wordt voortaan ‘1’.
          </li>
          <li>
            Na een beëindiging van een pand- of vruchtgebruik bleef dit gebruik toch staan in een
            export. Dit is nu aangepast.
          </li>
          <li>
            In sommige gevallen werd ten onrechte de datum van 1 januari 1970 voor de erkenning /
            betekening vermeld in een export. Dit is aangepast waardoor die datum niet meer wordt
            getoond als die niet is ingevuld.
          </li>
        </ul>
      </div>
    ),
    media: image_20220725_export_nl.src,
  },
  {
    date: parseISO('2022-07-25T12:00:00+0200'),
    title: 'Kleine verbeteringen en bugfixes',
    content: (
      <div>
        <ul>
          <li>
            Bij het wijzigen van een adres van een buitenlandse aandeelhouder zijn niet langer alle
            adresvelden verplicht om in te vullen. Het is voldoende als één adresveld is gevuld.
          </li>
          <li>
            Bij het invoeren van de gegevens van een natuurlijk persoon zijn de geboortedatum en
            geboorteplaats niet langer verplichte velden. Deze zijn immers niet in alle gevallen
            bekend.
          </li>
          <li>
            Er kan nu met de algemene zoekfunctie ook worden gezocht op een roepnaam van een
            aandeelhouder.
          </li>
          <li>
            Diverse Nederlandse en Engelstalige teksten in Evidend zijn gewijzigd zodat deze teksten
            duidelijker en consistenter zijn.
          </li>
          <li>
            Bij het invoeren van een punt in de duizendtallen van een aandelenreeks werd deze punt
            ten onrechte gelezen als een komma. Vanaf nu wordt de punt wel op de juiste manier
            gelezen.
          </li>
        </ul>
      </div>
    ),
    media: image_default.src,
  },

  /**
   * Release 2022-09-09
   */
  {
    date: parseISO('2022-09-09T12:00:00+0200'),
    title: 'Uittreksel van het register',
    content: (
      <div>
        <p>
          De mogelijkheid is toegevoegd om gemakkelijk een uittreksel te maken voor een specifieke
          partij waarmee voldaan kan worden aan BW 2:194, lid 4. Bij het exporteren van het register
          kan nu de keuze gemaakt worden om tussen het volledige register of een uittreksel. Voor
          het uittreksel kan een aandeelhouder geselecteerd worden. De pdf zal in dat geval alleen
          de pagina’s bevatten die relevant zijn voor die aandeelhouder.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2022-09-09T12:00:00+0200'),
    title: 'Percentage en aantal aandelen niet tonen in vennootschapsstructuur',
    content: (
      <div>
        <p>
          Standaard staat de weergave van het aantal aandelen en het percentage altijd aan. Als je
          een export van de vennootschapsstructuur wil hebben zonder deze waarden, dan moet je de
          weergaveoptie uitzetten.
        </p>
        <p>
          Het is mogelijk om het aantal aandelen en het percentage per aandeelhouder niet te tonen
          in de vennootschapsstructuur. Klik daarvoor rechtsonder op de drie streepjes in het
          menubalkje waarmee je dit scherm kunt in- en uitzoomen en vergroten/verkleinen etc..
        </p>
        <p>Deze functie heet ‘Weergaveopties – Toon percentages’.</p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2022-09-09T12:00:00+0200'),
    title: 'Overzicht van mutaties per register',
    content: (
      <div>
        <p>
          Een gebruiker van Evidend met beheerrechten kan nu een overzicht openen waarin alle
          mutaties per register staan vermeld. Hier kun je ook het resterende mutatietegoed vinden.
          Dit overzicht is te openen via het menu “Mijn kantoor”. Dit is dus alleen beschikbaar voor
          beheerders van het Evidend-account.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2022-09-09T12:00:00+0200'),
    title: 'Kleine verbeteringen en bugfixes',
    content: (
      <div>
        <ul>
          <li>Duidelijke uitleg bij invoer van een foutief KVK-nummer.</li>
          <li>
            In de documententab van een registeroverzicht werd de kolom passeerdatum niet juist
            gesorteerd. Dit is verholpen.
          </li>
          <li>
            Wanneer je het soort aandelen ‘Zelf definiëren’ gebruikt in een workflow en je teruggaat
            naar de stap “Soorten aandelen definiëren” kon je stemrecht en dividend niet meer
            wijzigen. Nu is het wel mogelijk om deze items te wijzigen.
          </li>
        </ul>
      </div>
    ),
    media: image_default.src,
  },

  /**
   * Release 2022-10-14
   */
  {
    date: parseISO('2022-10-14T10:00:00+0200'),
    title: 'Aanpassen (aankomst)titel',
    content: (
      <div>
        <p>
          Voorheen was de titel van een rechtshandeling in Evidend altijd hetzelfde als de naam van
          de mutatie.
        </p>
        <p>
          Vanaf nu is het mogelijk om die titel aan te passen. Hierdoor is het mogelijk om aan te
          geven dat het bijvoorbeeld gaat om een “Oprichting als het gevolg van splitsing” of om een
          toekenning in plaats van een uitgifte.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2022-10-14T10:00:00+0200'),
    title: 'Beëindiging vennootschap of stichting',
    content: (
      <div>
        <p>
          Ook het registreren van de beëindiging van de vennootschap is nu mogelijk. Hiervoor is een
          mutatie toegevoegd, waarbij de datum en reden van beëindiging worden ingevoerd. Een
          beëindigd register blijft beschikbaar in Evidend, zodat de status van het register op het
          moment van beëindiging nog kan worden teruggevonden. Bij het raadplegen van het register
          in Evidend en op de geëxporteerde PDF is dan duidelijk aangegeven dat de vennootschap is
          beëindigd.
        </p>
        <p>
          In combinatie met het kunnen aanpassen van de titel, kan hiermee een fusie of splitsing
          correct worden verwerkt in Evidend.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2022-10-14T10:00:00+0200'),
    title: 'Bewind(voering)',
    content: (
      <div>
        <p>
          Bewind is toegevoegd als nieuwe mutatie. Deze mutatie is te starten vanuit het tabblad
          mutaties of vanuit het tabblad aandelenverdeling voor een reeks. Hiermee kan worden
          aangegeven dat voor een reeks aandelen een bewindvoerder is aangesteld en wie de
          bewindvoerder is.
        </p>
        <p>Als eenmaal een bewind geregistreerd is, kan dit ook weer beëindigd worden.</p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2022-10-14T10:00:00+0200'),
    title: 'Exporteren van gegevens',
    content: (
      <div>
        <p>
          Naast het exporteren van een PDF van het gehele register of een uittreksel voor een
          specifieke partij uit het register, is het nu ook mogelijk om een csv bestand te
          exporteren. Het csv bestand bevat een overzicht van de aandelenverdeling of het
          gecertificeerd kapitaal. Daarnaast is in dit bestand ook zichtbaar bij wie de winst- en
          stemrechten liggen als die door de houder van de aandelen zijn overgedragen aan een andere
          partij.
        </p>
        <p>
          Het csv bestand kan worden ingelezen in een spreadsheetapplicatie zoals Excel of Numbers.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2022-10-14T10:00:00+0200'),
    title: 'Waarnemer of toegevoegd notaris kunnen registreren',
    content: (
      <div>
        <p>
          In het register is het ook wenselijk om te zien dat een akte is gepasseerd voor een
          waarnemer of toegevoegd notaris. Vanaf nu kan deze informatie worden toegevoegd bij het
          invoeren van de gegevens van de akte.
        </p>
        <p>
          NB: op dit moment is deze informatie wel beschikbaar in Evidend zelf, maar nog niet in de
          geëxporteerde PDF. Dit verwachten we spoedig toe te voegen.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2022-10-14T10:00:00+0200'),
    title: 'Kleine verbeteringen en bugfixes, zoals:',
    content: (
      <div>
        <ul>
          <li>
            Nog meer verbeteringen in de snelheid van de applicatie bij het verwerken van zeer grote
            registers
          </li>
          <li>
            Een bug verholpen waardoor aanpassingen in gegevens van aandeelhouders ongedaan werden
            gemaakt als de mutatie waarin ze waren opgevoerd werd gecorrigeerd.
          </li>
        </ul>
      </div>
    ),
    media: image_default.src,
  },

  /**
   * Release 2022-11-23
   */
  {
    date: parseISO('2022-11-23T10:00:00+0200'),
    title: 'Verschillende soorten notarissen weergave in export (PDF)',
    content: (
      <div>
        <p>
          Het is in Evidend al mogelijk om aan te geven of een akte is gepasseerd voor een
          ‘waarnemer’ of ‘toegevoegd notaris’. In deze release is deze informatie ook toegevoegd aan
          de geëxporteerde PDF.
        </p>
        <p>Voorbeelden: </p>
        <p>‘Initiële uitgifte door een waarnemer van mr. A. de Jong, notaris te Amsterdam’</p>
        <p>
          ‘Initiële uitgifte door mr. A. de Jong, toegevoegd notaris in het protocol van mr. B. den
          Ouden, notaris te Amsterdam’
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2022-11-23T10:00:00+0200'),
    title: 'Beëindiging vennootschap of stichting',
    content: (
      <div>
        <p>
          Het registreren van de beëindiging van de vennootschap is al mogelijk. Hiervoor was al een
          mutatie toegevoegd, waarbij de datum en reden van beëindiging worden ingevoerd. Nu kun je
          deze functie ook starten met de 3 puntjes rechtsboven in het overzicht van een register.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  /**
   * Release 2023-04-05
   */
  {
    date: parseISO('2023-04-05T10:00:00+0200'),
    title: 'Beslag',
    content: (
      <div>
        <p>
          Het is vanaf nu mogelijk om in Evidend te registreren dat er beslag gelegd is op aandelen.
          Hiervoor is de mutatie Beslag toegevoegd. Hierin kan je vastleggen op welke aandelen
          beslag is gelegd, wat het type beslag is (executoriaal of conservatoir), op last van welke
          partij het beslag is gelegd en welke deurwaarder betrokken is. Uiteraard is er ook een
          mutatie toegevoegd om te registreren dat het beslag weer is opgeheven.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  /**
   * Release 2023-04-12
   */
  {
    date: parseISO('2023-04-12T17:00:00+0200'),
    title: 'Opmerking bij aandeelhouder',
    content: (
      <div>
        <p>
          Het is vanaf nu mogelijk om een opmerking toe te voegen bij een aandeelhouder,
          certificaathouder of andere partij in het register. Hiervoor is het tabblad “partijen”
          toegevoegd. Op dit tabblad is een overzicht te zien van alle partijen die een rol spelen
          in het register.
        </p>
        <p>
          Via de drie puntjes aan het eind van de regel kan een opmerking worden toegevoegd aan de
          partij. Deze opmerking zal alleen zichtbaar zijn binnen het huidige register.
        </p>
        <p>
          Deze opmerking kan bijvoorbeeld gebruikt worden om aan te geven dat de aandeelhouder niet
          gevonden is aan bepaalde statutaire verplichtingen of dat deze aandelen houdt namens een
          CV.
        </p>
        <p>
          Tevens is het mogelijk om vanaf dit nieuwe tabblad partijen te verwijderen die per abuis
          zijn aangemaakt.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2023-04-12T17:00:00+0200'),
    title: 'Uitbreiding csv export',
    content: (
      <div>
        <p>
          De gegevens die zijn opgenomen in de csv export zijn uitgebreid met de contactgegevens die
          bekend zijn in Evidend. Hiermee kan de csv export een basis vormen voor een uitnodiging
          voor een aandeelhoudersvergadering of een dividendnota.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  /**
   * Release 2023-06-08
   */
  {
    date: parseISO('2023-06-08T15:00:00+0200'),
    title: 'Historie van gegevens van rechtspersonen',
    content: (
      <div>
        <p>
          Het is vanaf nu mogelijk om de historie van de bv (nv, stak) of de aandeelhouders
          zichtbaar te houden in Evidend.
        </p>
        <p>
          Bij het verversen van de gegevens van de kvk zal Evidend opmerken als er gegevens zijn
          gewijzigd. In dat geval wordt het mogelijk om de ingangsdatum hiervoor op te geven en
          eventueel de bijbehorende statutenwijziging te uploaden.
        </p>
        <p>
          De gewijzigde gegevens worden bijgehouden in de historie van de rechtspersoon. De historie
          is te vinden achter de drie puntjes rechts bovenin. Hier kunnen gewijzigde gegevens worden
          geraadpleegd, bijvoorbeeld om vast te stellen dat een aandeelhouder met een gewijzigde
          naam toch echt dezelfde aandeelhouder is.
        </p>
        <p>Ook voor buitenlandse vennootschappen wordt de historie bijgehouden.</p>
        <p>
          In de pdf-export wordt de geschiedenis van zowel de vennootschap zelf als de
          aandeelhouders weergegeven.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2023-06-08T15:00:00+0200'),
    title: 'Inwerkingtreding kan afwijken van passeerdatum',
    content: (
      <div>
        <p>
          Tot nu toe was de inwerkingtreding van een mutatie in Evidend altijd hetzelfde als de
          passeerdatum van de akte. Hoewel dit in de praktijk bijna altijd het geval is, hoeft dit
          helemaal niet zo te zijn. Mutaties die het gevolg zijn van fusies of splitsingen treden
          bijvoorbeeld juist altijd een dag na de passeerdatum in werking. Vanaf nu is het voor alle
          mutaties daarom mogelijk om apart een inwerkingtredingsdatum in te vullen.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2023-06-08T15:00:00+0200'),
    title: 'Tags voor partijen',
    content: (
      <div>
        <p>
          Aan het partijenscherm is nu de mogelijkheid toegevoegd om tags toe te voegen aan een
          partij en zo de partijen met specifieke bijzonderheden te markeren.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2023-06-08T15:00:00+0200'),
    title: 'Groepen voor certificaathouders',
    content: (
      <div>
        <p>
          Voor gebruikers van “Evidend voor het Certificaathoudersregister” is er een uitgebreidere
          manier van classificeren toegevoegd. Voor deze gebruikers is een scherm toegevoegd met
          groepen. Hier is het mogelijk om alle of een deel van de certificaten van een
          certificaathouder toe te kennen aan een zelf te definiëren groep.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  /**
   * Release 2023-07-03
   */
  {
    date: parseISO('2023-07-03T17:00:00+0200'),
    title: 'Werken in niet-chronologische volgorde',
    content: (
      <div>
        <p>
          Tot nog toe was het alleen maar mogelijk om in de chronologische volgorde te werken in
          Evidend. Daardoor was het erg lastig om een gemiste mutatie in het verleden alsnog in te
          voeren of een correctie uit te voeren in een eerdere mutatie.
        </p>
        <p>
          Vanaf heden is het mogelijk om een mutatie op enig moment na de initiële mutatie in te
          voeren. Evidend zal de nieuwe mutatie zelf op de juiste plek tussen de reeds ingevoerde
          mutaties plaatsen. Als deze mutatie een dusdanig invloed heeft op erop volgende mutaties
          dat het register niet meer kloppend is, dan zullen de betreffende mutaties gemarkeerd
          worden, zodat je snel kunt zien waar nog iets hersteld moet worden.
        </p>
        <p>
          Waar het voorheen alleen mogelijk was om de meest recente mutatie te corrigeren of te
          verwijderen is dat nu mogelijk voor alle mutaties. Ook hier geldt dat Evidend de erop
          volgende mutaties opnieuw zal controleren en aangeven als de aanpassing ervoor gezorgd
          heeft dat ze niet meer correct zijn.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2023-07-03T17:00:00+0200'),
    title: 'Statutenwijzigingen',
    content: (
      <div>
        <p>
          Het is nu mogelijk om de laatste statutenwijziging in te voeren en terug te vinden in
          Evidend. Voor enkele mutaties (bv de conversie) is het mogelijk om aan te geven dat het
          een statutenwijziging betreft. Daarnaast is er ook een aparte mutatie toegevoegd voor
          wijzigingen in de Statuten die geen effect hebben op het aandelenkapitaal.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2023-07-03T17:00:00+0200'),
    title: 'Groepen voor certificaathouders',
    content: (
      <div>
        <p>
          Voor de groepenfunctionaliteit die bij de vorige release is toegevoegd is nu ook een csv
          export beschikbaar zodat de verdeling van certificaten over de groepen ook beschikbaar
          wordt voor verdere verwerking.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2023-07-03T17:00:00+0200'),
    title: 'Pagina’s voor pandhouders, vruchtgebruikers en bewindvoerders',
    content: (
      <div>
        <p>
          In de pdf export worden pagina’s pandhouders, vruchtgebruikers en bewindvoerders nu altijd
          opgenomen, ook als ze leeg zijn. Zo is voor de lezer van de pdf sneller duidelijk wanneer
          dit niet voorkomt.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2023-07-03T17:00:00+0200'),
    title: 'Kleine verbeteringen',
    content: (
      <ul>
        <li>Betere ondersteuning van buitenlandse adressen </li>
        <li>Voorgedefinieerde aandelensoorten zijn terug van weggeweest</li>
        <li>De rechtsvorm wordt nu ook vertaald wanneer gekozen wordt voor Engels</li>
        <li>Het is mogelijk om meerdere pandrechten te vestigen op dezelfde aandelen</li>
      </ul>
    ),
    media: image_default.src,
  },
  /**
   * Release 2023-08-09
   */
  {
    date: parseISO('2023-08-09T11:00:00+0200'),
    title: 'Snel de aankomsttitel vinden',
    content: (
      <div>
        <p>
          Het is in Evidend mogelijk om aktes op te slaan bij een mutatie. Dit is nuttig bij de
          titelrecherche, maar het was nog lastig om de juiste aankomsttitel te vinden bij een serie
          aandelen, met name als er veel mutaties zijn geweest. Het is nu mogelijk om in het
          aandelenoverzicht bij een reeks via het menu aan de rechterkant te klikken op
          “Aankomsttitel” en zo direct de bijbehorende mutatie(s) te vinden met daarin de details
          van de aankomsttitel en het toegevoegde document. Ook voor bijvoorbeeld verpandingen en
          vruchtgebruik kun je nu in de kolom bijzonderheden de tag aanklikken om direct naar de
          details van de bijbehorende mutatie te gaan.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2023-08-09T11:00:00+0200'),
    title: 'Erfopvolging',
    content: (
      <div>
        <p>
          Evidend voorzag nog niet goed in het verwerken van het overlijden van een natuurlijk
          persoon waarbij diens aandelen overgaan naar de erven van. Het is nu mogelijk om bij een
          natuurlijk persoon een overlijdensdatum in te vullen. Hiermee is duidelijk aandelen niet
          meer gehouden worden door de persoon zelf, maar door diens erven.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2023-08-09T11:00:00+0200'),
    title: 'Certificaathouders met vergaderrecht',
    content: (
      <div>
        <p>
          Er was nog geen goede manier om certificaathouders met vergaderrecht in een
          aandeelhoudersregister op te nemen in Evidend. Nu kan dat wel. Op het tabblad partijen is
          het nu mogelijk om partijen toe te voegen en een startdatum voor het vergaderrecht in te
          geven. Zo is snel een overzicht van de vergaderrechten terug te vinden op de partijen tab.
          In de pdf export worden deze certificaathouders met vergaderrecht ook opgenomen.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2023-08-09T11:00:00+0200'),
    title: 'Administratievoorwaarden',
    content: (
      <div>
        <p>
          In de vorige release was al de mogelijkheid toegevoegd om statutenwijzigingen te kunnen
          verwerken in Evidend. Voor certificaathoudersregisters is nu ook de mogelijkheid tot het
          registeren van wijzigingen in de administratievoorwaarden op dezelfde manier aan
          toegevoegd.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2023-08-09T11:00:00+0200'),
    title: 'Laatst geopende registers zichtbaar op startpagina',
    content: (
      <div>
        <p>
          Op het dashboard op de startpagina van Evidend staat altijd een klein aantal registers om
          snel naartoe te kunnen. Deze registers waren altijd alfabetisch geordend. Dat hebben we
          gewijzigd, zodat nu de 6 laatst geopende registers bovenaan staan. Hierdoor is het
          makkelijker om de registers terug te vinden waaraan je kantoor het laatst heeft gewerkt.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2023-08-09T11:00:00+0200'),
    title: 'Kleine correcties en verbeteringen',
    content: (
      <ul>
        <li>Bij het volstorten van aandelen is het niet meer nodig om een document op te geven</li>
        <li>
          Bij het overdragen van het register wordt voortaan het e-mailadres uit de contactgegevens
          alvast ingevuld
        </li>
        <li>
          Als er meerdere verpandingen of vruchtgebruiken zijn voor dezelfde aandelen is het nu
          makkelijker om de juiste te beëindigen
        </li>
        <li>
          De service die ervoor zorgde dat het bij het invullen van een adres voldoende was om
          alleen postcode en huisnummer in te voeren stopte vorige week met werken. We hebben dit
          hersteld.
        </li>
      </ul>
    ),
    media: image_default.src,
  },
  /**
   * Release 2023-09-05
   */
  {
    date: parseISO('2023-09-05T16:00:00+0200'),
    title: 'Delen van informatie beperkt',
    content: (
      <div>
        <p>
          Bij het delen van een register in Evidend werd tot nog toe alle ingevoerde informatie
          gedeeld. Dit hebben we teruggebracht, zodat voortaan alleen nog de informatie wordt
          gedeeld die strikt genomen bij het aandeelhoudersregister hoort.
        </p>
        <p>
          Concreet betekent dit dat documenten die niet bij een mutatie horen, contactgegevens van
          de vennootschap en persoonlijke gegevens van natuurlijke personen alleen nog beschikbaar
          zijn voor beheerders van het register.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2023-09-05T16:00:00+0200'),
    title: 'Kleine correcties en verbeteringen',
    content: (
      <ul>
        <li>
          Bij het voltooien van bepaalde mutaties was het soms mogelijk om de mutatie meer dan eens
          uit te voeren door meermaals op “Opslaan” te klikken. Dit wordt voortaan voorkomen.
        </li>
        <li>
          In bepaalde gevallen werd niet duidelijk gemaakt of een postcode herkend was. Bij een
          ingevulde postcode wordt nu wel weer altijd het gevonden adres getoond.
        </li>
      </ul>
    ),
    media: image_default.src,
  },
  /**
   * Release 2023-11-06
   */
  {
    date: parseISO('2023-11-06T17:00:00+0200'),
    title: 'Delen met iemand die nog geen account heeft voor Evidend',
    content: (
      <div>
        <p>
          In bepaalde gevallen wil je iemand, bijvoorbeeld een notaris, toegang geven tot een
          register, om daar wijzigingen in door te voeren. Diegene zal niet altijd een licentie op
          Evidend hebben.
        </p>
        <p>
          Het is nu mogelijk om een register te delen met partijen die geen licentie hebben en hen
          rechten geven om mutaties door te voeren.
        </p>
        <p>
          Degene met wie je het register deelt kan een gratis account aanmaken en het gedeeld
          register raadplegen en bewerken.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2023-11-06T17:00:00+0200'),
    title: 'Gratis account aanmaken',
    content: (
      <div>
        <p>
          Als je een register deelt met iemand die nog geen Evidendgebruiker is, dan zal diegene een
          e-mail ontvangen met uitleg over hoe het gratis account aan te maken en welke
          mogelijkheden het biedt. Nadat met het nieuwe account is ingelogd geeft het direct toegang
          tot het gedeelde register en kan het in het vervolg ook gebruikt worden voor andere
          gedeelde registers.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2023-11-06T17:00:00+0200'),
    title: 'Activiteitenlog',
    content: (
      <div>
        <p>
          Voor een register dat veel gedeeld wordt is het belangrijk om na te kunnen blijven gaan
          wie welke wijziging heeft aangebracht. Daarom hebben we een activiteitenlog toegevoegd.
          Dit kan voor elk register gevonden worden onder de drie puntjes rechtsbovenaan. De
          activiteitenlog is beschikbaar voor de beheerders van een register en bevat een overzicht
          waarin je kunt zien welke wijzigingen zijn aangebracht, wie dat heeft gedaan en wanneer
          dat is gebeurd.
        </p>
        <p>
          Dit overzicht is alleen beschikbaar voor beheerders, dus niet voor iedereen met wie het
          register gedeeld wordt.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  /**
   * Release 2023-11-20
   */
  {
    date: parseISO('2023-11-20T18:00:00+0200'),
    title: 'Tijdelijke leestoegang zonder account',
    content: (
      <div>
        <p>
          Om het makkelijker te maken om een register te laten inzien door een derde partij, is het
          nu mogelijk om zelfs zonder account een register in te zien. Wanneer je een register deelt
          met iemand die nog geen evidend account heeft en je kiest ervoor om alleen alleen inzage
          te geven zonder bewerkrechten (“kan bekijken”), dan ontvangt diegene een e-mail met een
          link om direct het register te kunnen raadplegen. De link verloopt weer na 14 dagen.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2023-11-20T18:00:00+0200'),
    title: 'Beperkte weergave van vennootschapsstructuur',
    content: (
      <div>
        <p>
          De structuurweergave in Evidend kan zeer uitgebreid worden. Dit geeft een compleet beeld,
          maar soms wil je inzoomen op dat wat relevant is voor een bepaalde entiteit. Daarom is het
          vanaf nu mogelijk om in de vennootschapsstructuur via de weergaveopties rechtsonder te
          wisselen tussen de volledige structuur en een beperkte versie. In de beperkte versie
          worden alleen de lijnen naar aandeelhouders en participaties gevolgd. Daardoor ontstaat
          eenoverzichtelijk beeld voor de geselecteerde entiteit.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  /**
   * Release 2023-12-14
   */
  {
    date: parseISO('2023-12-14T17:00:00+0200'),
    title: 'Wijzigingen in eigenschappen van aandelen',
    content: (
      <div>
        <p>
          Soorten aandelen wijzigen was tot nu toe niet altijd makkelijk. Evidend bood nog geen
          goede manier om soorten aandelen te beheren. Bij een foutje bij het aanmaken van een
          nieuwe soort was het daarom in sommige gevallen nodig om de gehele mutatie opnieuw te
          doen. Ook bijvoorbeeld wijzigingen in het maatschappelijk kapitaal waren alleen via een
          omweg mogelijk.
        </p>
        <p>
          Daarom is aan het register de tab “soorten aandelen” toegevoegd. Hierop vind je een
          overzicht van de gebruikte soorten aandelen en eventuele versies ervan als ze in de loop
          van de tijd zijn gewijzigd. Correcties kunnen hier ook direct worden aangebracht.
        </p>
        <p>
          Daarnaast is in de relevante mutaties een stap toegevoegd waarin het mogelijk is om een
          wijziging in de soort aandelen direct te verwerken. Als bijvoorbeeld het maatschappelijk
          kapitaal wordt verhoogd en hier direct een uitgifte voor wordt gedaan, dan kun je in de
          mutatie “uitgifte” het maatschappelijk kapitaal aanpassen (en uiteraard ook nog steeds
          aangeven dat de mutatie een statutenwijziging betreft).
        </p>
        <p>
          Deze nieuwe functies zijn uiteraard ook beschikbaar voor aanpassingen van certificaten in
          registers voor een stichting.
        </p>
        <p>
          Bekijk de{' '}
          <a href="https://youtu.be/AaxLNoRdB3Q" target="_blank">
            instructievideo
          </a>{' '}
          voor deze nieuwe toevoeging
        </p>
      </div>
    ),
    media: image_default.src,
  },
  /**
   * Release 2023-12-22
   */
  {
    date: parseISO('2023-12-22T15:00:00+0200'),
    title: 'Wijzigingen in kosten van mutaties',
    content: (
      <div>
        <p>
          Per 1 januari 2024 zullen de kosten van een mutatie worden verhoogd van 25 naar 30 credits
          of van 10 naar 12 credits voor mutaties met een transactiedatum van vóór 1 januari 2022.
        </p>
        <p>
          Huidige bestaande tegoeden zullen op 2 januari 2024 20% worden verhoogd om deze
          prijsaanpassing te compenseren.
        </p>
        <p>
          Het is mogelijk om mutatiebundels te kopen, met een oplopende korting. Neem hiervoor
          contact op met je accountmanager of via{' '}
          <a href="mailto:evidend@sdu.nl" target="_blank">
            evidend@sdu.nl
          </a>
          .
        </p>
      </div>
    ),
    media: (
      <table>
        <thead>
          <tr>
            <th>
              Mutatiebundels
              <br />
              (credits)
            </th>
            <th>Kosten</th>
            <th>Korting</th>
            <th>Prijs per mutatie</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>100</td>
            <td>€ 100,00</td>
            <td>0%</td>
            <td>€ 30,00</td>
          </tr>
          <tr>
            <td>250</td>
            <td>€ 243,75</td>
            <td>2,5%</td>
            <td>€ 29,25</td>
          </tr>
          <tr>
            <td>500</td>
            <td>€ 475,00</td>
            <td>5%</td>
            <td>€ 28,50</td>
          </tr>
          <tr>
            <td>1.000</td>
            <td>€ 900,00</td>
            <td>10%</td>
            <td>€ 27,00</td>
          </tr>
          <tr>
            <td>1.500</td>
            <td>€ 1.312,50</td>
            <td>12,5%</td>
            <td>€ 26,25</td>
          </tr>
          <tr>
            <td>2.000</td>
            <td>€ 1.700,00</td>
            <td>15%</td>
            <td>€ 25,50</td>
          </tr>
        </tbody>
      </table>
    ),
  },
  {
    date: parseISO('2023-12-22T15:00:00+0200'),
    title: 'Register delen met leesrechten',
    content: (
      <div>
        <p>
          In een vorige release introduceerden we het tijdelijk delen van een register. Hierdoor
          verviel de optie om een register voor onbepaalde tijd met alleen leesrechten te delen met
          een partij die nog geen account in Evidend heeft. Omdat er ook behoefte is aan deze wijze
          van delen hebben we nu de keuze toegevoegd. Delen met leesrechten kan nu tijdelijk voor 14
          dagen, laagdrempelig zonder dat de ontvanger een account hoeft aan te maken, of voor
          onbepaalde tijd waarbij de ontvanger wel een (gratis) account aanmaakt.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  /**
   * Release 2024-02-05
   *
   */
  {
    date: parseISO('2024-02-05T17:00:00+0200'),
    title: 'Dividendoverzicht',
    content: (
      <div>
        <p>
          Voor gebruikers met een abonnement voor Evidend Corporate Housekeeping of Evidend
          Certificaathoudersregister is het vanaf deze release mogelijk om een overzicht te creëren
          van het uit te keren dividend op basis van de verdeling van aandelen of certificaten.
          Hiervoor klik je vanuit een register rechtsboven op de drie puntjes en kies je voor
          “Dividendoverzicht”.
        </p>
        <p>
          Hier kun je een nieuw overzicht aanmaken door een aantal gegevens in te voeren, waaronder
          het bedrag per aandeel of certificaat dat uitgekeerd wordt. Evidend berekent vervolgens op
          basis hiervan hoeveel dividend elke partij toekomt. Hierbij wordt uiteraard ook rekening
          gehouden met overgedragen winstrechten die zijn overgedragen aan een vruchtgebruiker.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2024-02-05T17:00:00+0200'),
    title: `Dividendnota's`,
    content: (
      <div>
        <p>
          Dit overzicht kan ook geëxporteerd worden in een csv formaat. Bovendien is het ook
          mogelijk om dividendnota&apos;s in pdf te genereren voor alle partijen in het overzicht.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2024-02-05T17:00:00+0200'),
    title: `Onbekende notaris`,
    content: (
      <div>
        <p>
          Soms is het bij het invoeren van historische mutaties niet meer te achterhalen wie de
          notaris is geweest die de akte heeft gepasseerd. Het was in Evidend altijd verplicht om de
          gegevens van de notaris in te vullen waardoor in voorkomende gevallen je bij het invoeren
          gedwongen was om de voor de naam van de Notaris “Onbekend” in te voeren. Het is nu
          mogelijk om bij het invoeren van de gegevens voor het document te kiezen voor “Notaris
          onbekend” zodat het niet meer gevraagd wordt om de gegevens van de notaris.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  /**
   * Release 2024-09-12
   *
   */
  {
    date: parseISO('2024-09-12T17:00:00+0200'),
    title: 'Digitaal ondertekenen',
    content: (
      <div>
        <p>
          We krijgen regelmatig het verzoek voor een manier voor het bestuur van een vennootschap om
          akkoord te geven op de aanpassingen die de notaris heeft gedaan in het register. Tot op
          heden ontbrak in Evidend nog een equivalent van de handtekening die gezet wordt in het
          papieren register.
        </p>
        <p>
          In deze update hebben we aan alle registers de mogelijkheid toegevoegd om een register na
          controle digitaal te ondertekenen. Evidend laat duidelijk zien of een register al is
          ondertekend en of er sinds de laatste ondertekening nog wijzigingen hebben plaatsgevonden.
          In dat laatste geval worden de mutaties sinds de laatste ondertekening getoond.
        </p>
        <p>In Evidend wordt vastgelegd wie heeft ondertekend en wanneer.</p>
        <p>
          Op de supportpagina is een video opgenomen met een uitgebreide uitleg over digitaal
          ondertekenen in Evidend.
        </p>
      </div>
    ),
    media: image_default.src,
  },
];
