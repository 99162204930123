import { AccountEntitlement, Locale } from '@elseu/sdu-evidend-graphql';
import type { SidebarItemProps } from '@elseu/sdu-titan';
import {
  ActionMenu,
  Avatar,
  BoltIcon,
  BusinessIcon,
  ButtonGroup,
  Clickable,
  Drawer,
  DrawerContainer,
  DrawerContent,
  DrawerList,
  DrawerListItem,
  ExitIcon,
  HelpIcon,
  HomeIcon,
  LightbulbIcon,
  Link,
  Loader,
  Logo,
  MenuItem,
  Navigation,
  PeopleIcon,
  PersonIcon,
  SearchBar,
  SearchForm,
  SettingsIcon,
  SwapHorizontalIcon,
  Text,
  UploadIcon,
  useGreaterThan,
  UserIcon,
  WorldIcon,
} from '@elseu/sdu-titan';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { JsonSidebarForm } from 'components/JsonSidebarForm/JsonSidebarForm';
import Onboarding from 'components/Onboarding/Onboarding';
import { config } from 'config';
import { formatISO } from 'date-fns';
import { getInitials } from 'helpers/initials';
import { localeToIso } from 'helpers/locale';
import { stripFromUrl } from 'helpers/stripFromUrl';
import { useAccount, useEntitlements } from 'hooks/useAccount';
import { useSetLocale } from 'hooks/useSetLocale';
import { useRouter } from 'next/router';
import {
  useAuthAccessClaims,
  useAuthControls,
  useAuthIsLoggedIn,
  useAuthUserInfo,
} from 'oidc-jwt-client';
import React, { useCallback, useMemo, useState } from 'react';
import { useCookie } from 'react-use';
import styled from 'styled-components';

interface SidebarNavigationProps {
  children?: React.ReactNode;
  hideOnboarding?: boolean;
  query?: string;
}

interface UserInfo {
  cn: string;
  email?: string;
  givenName?: string;
  login: string;
  name: string;
  sn: string;
  sub: string;
  updated_at: number;
}

const ClickableAvatar = styled(Clickable)`
  display: flex;
  justify-content: flex-end;
`;

const UnauthenticatedSidebarNavigationAvatar = () => {
  const { i18n } = useLingui();
  const { authorize } = useAuthControls();
  const isLarge = useGreaterThan('s');

  const setLocale = (locale: Locale) => {
    i18n.activate(localeToIso(locale));
  };

  const locales = [
    { value: Locale.DUTCH, label: 'Nederlands' },
    { value: Locale.ENGLISH_BRITISH, label: 'English (UK)' },
  ];

  if (isLarge) {
    return (
      <ButtonGroup spaceBetween={4}>
        <ActionMenu
          label={t`Taal`}
          popoverOptions={{
            strategy: 'fixed',
          }}
          popoverPlacement="bottom-start"
          trigger={
            <Link prefixAdornment={<WorldIcon />}>
              <Trans>Taal</Trans>
            </Link>
          }
        >
          {locales.map(({ label, value }) => (
            <MenuItem
              key={value}
              isActive={i18n.locale === value}
              item={{ label, value }}
              onClick={() => setLocale(value)}
            />
          ))}
        </ActionMenu>

        <Link prefixAdornment={<PersonIcon />} onClick={() => authorize()}>
          <Trans>Inloggen</Trans>
        </Link>
      </ButtonGroup>
    );
  }

  return (
    <ButtonGroup spaceBetween={4}>
      <ActionMenu
        label={t`Taal`}
        popoverOptions={{
          strategy: 'fixed',
        }}
        popoverPlacement="bottom-start"
        trigger={
          <Clickable aria-label={t`Taal`}>
            <Avatar backgroundColor="grey20" foregroundColor="grey60" size={48}>
              <WorldIcon />
            </Avatar>
          </Clickable>
        }
      >
        {locales.map(({ label, value }) => (
          <MenuItem
            key={value}
            isActive={i18n.locale === value}
            item={{ label, value }}
            onClick={() => setLocale(value)}
          />
        ))}
      </ActionMenu>

      <Clickable aria-label={t`Inloggen`} onClick={() => authorize()}>
        <Avatar size={48} />
      </Clickable>
    </ButtonGroup>
  );
};

const UnauthenticatedSidebarNavigation = () => {
  return (
    <Navigation
      isSticky
      avatar={<UnauthenticatedSidebarNavigationAvatar />}
      brandName="Evidend"
      logoComponent={<Logo brandName="Evidend" onClick={() => window.location.reload()} />}
    />
  );
};

export const SidebarNavigation = ({ children, query, hideOnboarding }: SidebarNavigationProps) => {
  const linguiCtx = useLingui();
  const [isDrawerShown, setIsDrawerShown] = useState<boolean>(false);
  const [isRegisterDrawerShown, setIsRegisterDrawerShown] = useState<boolean>(false);
  const router = useRouter();
  const { value: userInfo, loading: isLoadingUserInfo } = useAuthUserInfo<UserInfo>();
  const { logout, authorize } = useAuthControls();
  const { value: claims, loading: isLoadingClaims } = useAuthAccessClaims<any>();
  const isLoggedIn = useAuthIsLoggedIn();
  const [magicLink, setMagicLink] = useCookie('magic_link');
  const [, hasMyOffice] = useEntitlements([AccountEntitlement.MY_OFFICE]);

  const isAnonymousUser = claims?.anon;
  const account = useAccount();
  const [isSearchShown, setSearchShown] = useState(false);

  // Action to reset the release notes date for preview.
  const [, setLastSeen] = useCookie('onboarding');
  const resetReleaseNotes = useCallback(() => {
    setLastSeen(formatISO(new Date('2023-01-01')));
    window.location.reload();
  }, [setLastSeen]);

  const initials = useMemo(() => getInitials(userInfo?.cn || ''), [userInfo?.cn]);

  const handleSubmit = (input: string) => {
    router.push({
      pathname: '/zoeken',
      query: {
        query: input,
      },
    });
  };

  const onClickLogout = useCallback(() => {
    setMagicLink('');
    const post_logout_redirect_uri = stripFromUrl(window.location.href, 'magic_link');
    logout({ post_logout_redirect_uri });
  }, [logout, setMagicLink]);

  const setLocale = useSetLocale();

  const sidebarItems = useMemo(() => {
    const items: SidebarItemProps[] = [
      {
        id: '',
        title: t`Home`,
        icon: HomeIcon,
        to: '/',
      },
      {
        id: 'rechtspersoon',
        title: t`Mijn registers`,
        icon: BusinessIcon,
        to: '/registers',
      },
      {
        id: 'workflow',
        title: t`Workflows`,
        icon: BoltIcon,
        to: '/mutaties/overzicht',
      },
      {
        id: 'support',
        title: t`Support en contact`,
        icon: HelpIcon,
        to: '/support',
      },
    ];

    if (hasMyOffice) {
      items.push({
        id: 'office',
        title: t`Mijn kantoor`,
        icon: BusinessIcon,
        to: '/kantoor',
      });
    }
    if (account?.isAdmin) {
      items.push({
        id: 'admin',
        title: t`Admin`,
        icon: SettingsIcon,
        to: '',
        children: [
          {
            id: 'admin-user',
            title: t`Gebruikers`,
            icon: PeopleIcon,
            to: '/admin/users',
          },
          {
            id: 'admin-group',
            title: t`Groepen`,
            icon: BusinessIcon,
            to: '/admin/groups',
          },
        ],
      });
    }

    return items;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account?.isAdmin, hasMyOffice, linguiCtx]);

  if (isLoadingUserInfo || isLoadingClaims) {
    return (
      <div data-test-id="userProfileLoader">
        <Loader height={32} variant="spinner" width="30px" />
      </div>
    );
  }

  if (!isLoggedIn) {
    return <UnauthenticatedSidebarNavigation />;
  }

  return (
    <>
      <Navigation
        isSticky
        avatar={
          <ClickableAvatar onClick={() => setIsDrawerShown(true)}>
            <Avatar size={40}>{isAnonymousUser ? <BusinessIcon /> : initials}</Avatar>
          </ClickableAvatar>
        }
        brandName="Evidend"
        isSearchShown={isSearchShown}
        searchComponent={
          <SearchBar
            formElement={
              <SearchForm
                defaultValue={query}
                label={t`Zoeken`}
                placeholder={t`Zoeken naar registers, aandeelhouders of certificaathouders`}
                testId="searchElement"
                onSubmit={handleSubmit}
              />
            }
            isShown={isSearchShown}
            labelCancelButton={t`Annuleren`}
            onToggle={setSearchShown}
          />
        }
        sidebarItems={sidebarItems}
        onToggleSearch={setSearchShown}
      />

      {!hideOnboarding && <Onboarding />}

      <Drawer
        isShown={isDrawerShown}
        position="right"
        width={480}
        onClose={() => setIsDrawerShown(false)}
      >
        <DrawerContainer
          avatarIcon={isAnonymousUser ? <BusinessIcon /> : undefined}
          header={userInfo?.cn || <Trans>Anoniem</Trans>}
          headerMeta={
            <Text color="grey70" type="labelSmall">
              {userInfo?.email || ''}
            </Text>
          }
          headerVariant="avatar"
          initials={!isAnonymousUser ? initials : undefined}
        >
          <DrawerContent>
            <DrawerList variant="compact">
              {!magicLink && (
                <DrawerListItem linkTo={config.frontOfficeUrl} prefixAdornment={<UserIcon />}>
                  <Trans>Mijn account en producten</Trans>
                </DrawerListItem>
              )}
              <DrawerListItem linkTo="/support" prefixAdornment={<HelpIcon />}>
                <Trans>Support en contact</Trans>
              </DrawerListItem>

              {linguiCtx.i18n.locale !== 'en-gb' && (
                <DrawerListItem
                  prefixAdornment={<WorldIcon />}
                  onClick={() => setLocale(Locale.ENGLISH_BRITISH)}
                >
                  Change language to English (UK)
                </DrawerListItem>
              )}

              {linguiCtx.i18n.locale !== 'nl' && (
                <DrawerListItem
                  prefixAdornment={<WorldIcon />}
                  onClick={() => setLocale(Locale.DUTCH)}
                >
                  Wijzig taal naar Nederlands
                </DrawerListItem>
              )}

              {/* Include a debug button to trigger the release notes. */}
              {!config.featureFlags.includes('WITHOUT_DEBUG_RELEASE_NOTES') && (
                <DrawerListItem prefixAdornment={<SettingsIcon />} onClick={resetReleaseNotes}>
                  <Trans>Laatste release notes opnieuw tonen</Trans>
                </DrawerListItem>
              )}

              {account?.isAdmin && (
                <DrawerListItem
                  prefixAdornment={<UploadIcon />}
                  onClick={() => setIsRegisterDrawerShown(true)}
                >
                  <Trans>Register importeren</Trans>
                </DrawerListItem>
              )}

              <DrawerListItem linkTo="/wat-is-er-nieuw" prefixAdornment={<LightbulbIcon />}>
                <Trans>Wat is er nieuw?</Trans>
              </DrawerListItem>

              <DrawerListItem prefixAdornment={<ExitIcon />} onClick={onClickLogout}>
                <Trans>Uitloggen</Trans>
              </DrawerListItem>
              {claims?.amr?.includes('urn:els:auth:ip') && (
                <DrawerListItem
                  prefixAdornment={<SwapHorizontalIcon />}
                  onClick={() =>
                    authorize({ prompt: 'login', acr_values: 'urn:els:auth:usernamepwd' })
                  }
                >
                  <Trans>Inloggen op een ander account</Trans>
                </DrawerListItem>
              )}
            </DrawerList>
          </DrawerContent>
        </DrawerContainer>
      </Drawer>
      <JsonSidebarForm
        isSidebarShown={isRegisterDrawerShown}
        onSidebarClose={() => setIsRegisterDrawerShown(false)}
        onUpdate={(registerId) => {
          router.push(`/rechtspersoon/${registerId}`);
        }}
      />
      {children}
    </>
  );
};
