import React from 'react';

import { OnboardingMessage } from './OnboardingMessage';
import { OnboardingModal } from './OnboardingModal';
import { OnboardingProvider } from './OnboardingProvider';

const Onboarding = () => {
  return (
    <OnboardingProvider>
      <OnboardingMessage />
      <OnboardingModal />
    </OnboardingProvider>
  );
};

export default Onboarding;
