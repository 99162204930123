import { parseISO } from 'date-fns';
import type { ReleaseNote } from 'releases';

import image_20220630_correct_en from './images/2022-06-30/correct-en.png';
import image_20220630_group_en from './images/2022-06-30/group-en.png';
import image_20220630_preview_en from './images/2022-06-30/preview-en.png';
import image_20220725_export_en from './images/2022-07-25/export-en.png';
import image_20220725_ownership_en from './images/2022-07-25/ownership-en.png';
import image_20220725_whatsnew_en from './images/2022-07-25/whatsnew-en.png';
import image_default from './images/default.jpg';

/**
 * Always put new release notes at the bottom of the array (`date` field ascending).
 * Release notes with the same date should be ordered by importance, with most important first.
 *
 * Don't forget to add the English release notes too! No need to add t`` or <Trans> tags.
 */
export const notesEN: ReleaseNote[] = [
  /**
   * Release 2022-06-30
   */
  {
    date: parseISO('2022-06-30T12:00:00+0200'),
    title: 'Share capital and share distribution overviews',
    content: (
      <div>
        <p>
          The overviews on the tabs for share capital and share distribution have been improved. The
          different categories can still be opened by clicking them. A new search box is added on
          the top right that allows you to filter all categories at once.
        </p>

        <p>
          On the share distribution tab you can now also change the view of the information. The
          standard view is grouped by shareholder, but it is possible to select a view that is
          grouped per share type.
        </p>
      </div>
    ),
    media: image_20220630_group_en.src,
  },
  {
    date: parseISO('2022-06-30T12:00:00+0200'),
    title: 'New: Correcting a previous mutation',
    content: (
      <div>
        <p>
          It was already possible to undo the last completed mutation in a register to correct a
          mistake. However, in case of a big mutation this implied that the complete mutation had to
          be redone in case a small mistake was discovered. Now it is possible to correct the last
          completed mutation.
        </p>

        <p>
          By selecting <em>Correct</em> you can re-open the mutation and correct the data that was
          entered. Correcting a mutation will not incur any additional costs. This option is
          available for all mutations created after May 20
          <sup>th</sup>.
        </p>
      </div>
    ),
    media: image_20220630_correct_en.src,
  },
  {
    date: parseISO('2022-06-30T12:00:00+0200'),
    title: 'New: Document preview',
    content: (
      <div>
        <p>
          When many documents have been uploaded for a register, it may be a hassle to find the one
          that you are looking for. We added a preview function for pdf documents. Now it is no
          longer required to download a pdf document to see its contents.
        </p>

        <p>
          The preview can be accessed through the three dots on the right side of the document
          table.
        </p>
      </div>
    ),
    media: image_20220630_preview_en.src,
  },
  {
    date: parseISO('2022-06-30T12:00:00+0200'),
    title: 'Small improvements and bugfixes',
    content: (
      <div>
        <ul>
          <li>
            When downloading a large company structure texts could become illegible. The resolution
            of the downloaded image now scales with the size of the image so that it will always be
            legible.
          </li>
          <li>
            In the diagram view of the share distribution, percentages are now displayed with two
            decimals as they are in the company structure.
          </li>
          <li>It is now possible to export a pdf for a depositary receipt holders register.</li>
          <li>It is now possible to delete a previously uploaded document.</li>
          <li>Fixed a bug that caused addresses of natural persons to not be saved correctly.</li>
          <li>Fixed a bug that made it impossible to update the data of a shareholder</li>
        </ul>
      </div>
    ),
    media: image_default.src,
  },

  /**
   * Release 2022-07-25
   */
  {
    date: parseISO('2022-07-25T12:00:00+0200'),
    title: 'What’s new',
    content: (
      <div>
        <p>
          Starting today it is possible to see the latest changes that have been released for
          Evidend. The option “What’s new?” has been added to the menu on the right hand side (click
          the circle with your initials to open this menu).
        </p>
        <p> The latest release notes can now always be retrieved in this way. </p>
      </div>
    ),
    media: image_20220725_whatsnew_en.src,
  },
  {
    date: parseISO('2022-07-25T12:00:00+0200'),
    title: 'New: Custom identifier for natural persons',
    content: (
      <div>
        <p>
          It is now possible to add a custom identifier for natural persons. This free format field
          is not required, but when used the identifier is displayed with the natural person. It is
          also possible to search for the natural person in Evidend using the custom identifier.
        </p>
        <p>
          This field can be for example useful in depositary receipt holders registers for a family
          business. The name might not be sufficient to identify a natural person in such a
          register. It can also be used to add an identifier that is used in another system.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2022-07-25T12:00:00+0200'),
    title: 'Display of the share distribution',
    content: (
      <div>
        <p>
          In the diagram view of the share distribution it is possible to filter on share types, as
          well as to group all share types with voting or dividend rights together. It is also
          possible to view the distribution based on nominal value. Note that when filtered on
          shares with voting or dividend rights, the share distribution at the moment only shows who
          the owner of those shares is. Not who actually holds those voting or dividend rights
          should they have been transferred.
        </p>
      </div>
    ),
    media: image_20220725_ownership_en.src,
  },
  {
    date: parseISO('2022-07-25T12:00:00+0200'),
    title: 'Loading times for large workflows',
    content: (
      <div>
        <p>
          When more than 100 shareholders are added in a single workflow, the loading times for
          Evidend were increasing significantly. We have added several improvements that ensure that
          the loading times remain on par, even for these big mutations.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2022-07-25T12:00:00+0200'),
    title: 'Improvements to the export document (PDF)',
    content: (
      <div>
        <p>The following updates have been done for the export document (PDF):</p>
        <ul>
          <li>
            The share types in the register are now always correctly ordered on name of the share
            type, letter and nominal value.
          </li>
          <li>
            The nominal value is no longer rounded. So a nominal value of 0.000001 is no longer
            displayed as 0.00.
          </li>
          <li>
            The pages for authorised capital and (not) paid up capital are now always included, even
            if they are empty. This shows the state of the register more clearly than leaving them
            out when they are empty.
          </li>
          <li>
            The series of a single share is no longer displayed as a range. So a single share is
            displayed as, for example, “1” instead of “1-1”.
          </li>
          <li>
            After termination of a pledge or usufruct, the export was not properly displayed in the
            export on the pages for the pledgee or usufructuary. This has been corrected.
          </li>
          <li>
            In some cases the date of January 1st 1970 was erroneously displayed for
            acknowledgement. This has been corrected.
          </li>
        </ul>
      </div>
    ),
    media: image_20220725_export_en.src,
  },
  {
    date: parseISO('2022-07-25T12:00:00+0200'),
    title: 'Small improvements and bugfixes',
    content: (
      <div>
        <ul>
          <li>
            Updating the information for a foreign legal entity required adding multiple addresses.
            It is now sufficient to provide one address.
          </li>
          <li>
            For natural persons, place and date of birth were always required. This is no longer the
            case, since this information is not always known.
          </li>
          <li>Searching on a nickname of a natural person is now also possible.</li>
          <li>
            Several Dutch and English texts have been updated so they are more clear and more
            consistent.
          </li>
          <li>
            When a period was entered as a thousand separator of a share series, it was incorrectly
            read as a comma. From now on, the period will be read correctly.
          </li>
        </ul>
      </div>
    ),
    media: image_default.src,
  },

  /**
   * Release 2022-09-09
   */
  {
    date: parseISO('2022-09-09T12:00:00+0200'),
    title: 'Extract of the register',
    content: (
      <div>
        <p>
          A feature is added that allows to easily create an extract for a specific party in order
          to comply with article 2:194 (4) Dutch Civil Code. When exporting the register you wil
          have the choice between a complete register and an extract. For the extract a party can be
          selected. The pdf will then only contain the pages related to that party.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2022-09-09T12:00:00+0200'),
    title: 'Percentage and number of shares not shown in corporate structure',
    content: (
      <div>
        <p>
          By default, the display of number of shares and percentages are displayed. If you want to
          have an export of the company structure without these values, you must disable this
          display option.
        </p>
        <p>
          It is possible to disable the number of shares and the percentage per shareholder in the
          corporate structure. Click on the three lines in the menubar at the bottom-right with
          which you can zoom in and out and enlarge/reduce this screen, etc..
        </p>
        <p>This function is called ‘Display options – Show percentages’.</p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2022-09-09T12:00:00+0200'),
    title: 'Overview of mutations by legal entity',
    content: (
      <div>
        <p>
          A user of Evidend with administrative rights can now open an overview in which all
          transactions per register are listed. This view also displays the remaining mutation
          balance for your office. This overview can be accessed by the menu item “My office”, and
          is only available for administrators of the Evidend account.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2022-09-09T12:00:00+0200'),
    title: 'Small improvements and bugfixes',
    content: (
      <div>
        <ul>
          <li>Clear explanation when entering an incorrect KVK number.</li>
          <li>
            In the documents tab of a legal entity overview, the column ‘pass date’ was not sorted
            correctly. This has been fixed.
          </li>
          <li>
            When you use sharetype ‘Customise’ in a workflow and you go back to the step ‘Share
            types’ it was no longer possible to update the voting and dividend rights. This is now
            possible.
          </li>
        </ul>
      </div>
    ),
    media: image_default.src,
  },

  /**
   * Release 2022-10-14
   */
  {
    date: parseISO('2022-10-14T10:00:00+0200'),
    title: 'Custom legal title',
    content: (
      <div>
        <p>
          Previously, the legal title in Evidend was always the same as the name of the mutation.
        </p>
        <p>
          It is now possible to define the title yourself. This allows for, for example, indicating
          an “incorporation as a result of a demerger” or an attribution instead of an issuance.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2022-10-14T10:00:00+0200'),
    title: 'Termination of the company ',
    content: (
      <div>
        <p>
          It is possible now to register the termination of the company. A new mutation is now
          available to enter the date and reason of the termination.
        </p>
        <p>
          A terminated register will remain available in Evidend, so that the status at the moment
          of termination can be found. On the register, it will be clearly marked that the company
          is terminated, in Evidend, as well as on the exported PDF.
        </p>
        <p>
          This addition combined with the customisable legal title, makes it now possible to
          correctly register a merger or demerger in Evidend.{' '}
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2022-10-14T10:00:00+0200'),
    title: 'Administration',
    content: (
      <div>
        <p>
          Administration has been added as a new mutation. This mutation can be started from the
          mutations tab or from the share ownership tab for a range of shares. Here you can indicate
          that a range is under administration and who the administrator is.
        </p>
        <p>Once an administration is registered for a range, it can also be terminated.</p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2022-10-14T10:00:00+0200'),
    title: 'Export of data',
    content: (
      <div>
        <p>
          In addition to the export of the PDF of the complete register, or the extract from a
          specific party, it is now also possible to export a csv file with an overview of the share
          distribution. The file will also contain the distribution of the dividend and voting
          rights, that may have been transferred to a third party.
        </p>
        <p>
          It is possible to import the csv file to a spreadsheet program such as Excel or Numbers.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2022-10-14T10:00:00+0200'),
    title: 'Deputy or assigned civil-law notary',
    content: (
      <div>
        <p>
          In the register it should be clear if a deed is executed by a deputy or assigned civil-law
          notary. It is now possible to specify this when entering the data of the deed.
        </p>
        <p>
          Note: currently, the information is available in Evidend, but it will not yet be reflected
          on the exported PDF. This will be added in the next release.{' '}
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2022-10-14T10:00:00+0200'),
    title: 'Minor improvements and bugfixes, such as:',
    content: (
      <div>
        <ul>
          <li>
            More improvements in the speed of the application when handling very large registers
          </li>
          <li>
            Fixed a bug that caused updates of shareholder information to become undone when
            correcting the mutation that first introduced the shareholder.
          </li>
        </ul>
      </div>
    ),
    media: image_default.src,
  },

  /**
   * Release 2022-11-23
   */
  {
    date: parseISO('2022-11-23T10:00:00+0200'),
    title: 'Different types of notaries view in export (PDF)',
    content: (
      <div>
        <p>
          It is already possible in Evidend to indicate whether a deed has been executed before an
          ‘deputy’ or ‘assigned notary’. In this release, this information is also added to the
          exported PDF.
        </p>

        <p>Examples:</p>

        <p>‘Initial issuance before a deputy of mr. A. de Jong, civil-law notary in Amsterdam’</p>

        <p>
          ‘Initial issuance before mr. A. de Jong, assigned civil-law notary whose deeds are
          archived in the records of mr. B. den Ouden, civil-law notary in Amsterdam’
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2022-11-23T10:00:00+0200'),
    title: 'Termination of the company',
    content: (
      <div>
        <p>
          It is already possible to register the termination of the company. A mutation was already
          added for this, in wich the date and reason for termination are entered. Now you can also
          start this function with the 3 dots at the top right in the overview of a register.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  /**
   * Release 2023-04-05
   */
  {
    date: parseISO('2023-04-05T10:00:00+0200'),
    title: 'Attachment',
    content: (
      <div>
        <p>
          It is now possible to register in Evidend that attachment has been levied on shares. The
          new mutation Attachment has been added for this purpose. With this mutation one can
          register the shares that are under attachment, the type of attachment (prejudgment or
          enforcable), the name of the bailiff and the party requiring the attachment. Of course, we
          also added a mutation to register that the attachment has been lifted.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  /**
   * Release 2023-04-12
   */
  {
    date: parseISO('2023-04-12T17:00:00+0200'),
    title: 'Comment about a shareholder',
    content: (
      <div>
        <p>
          It is now possible to add a comment to add a comment to a shareholder, depositary receipt
          holder or any other party in a register. We have added a new tab “parties” that provides
          an overview of all the parties that are part of the register.
        </p>
        <p>
          With the three dots at the end of each row, it is possible to add a comment to a party.
          This comment will only be visible within the current register.
        </p>
        <p>
          This comment may be added, for example, to indicate that a shareholder is exempt from
          certain statutory obligations, or that they hold shares on behalf of a CV.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2023-04-12T17:00:00+0200'),
    title: 'Additional data in csv export',
    content: (
      <div>
        <p>
          We have added the contact details that are known in Evidend to the csv export. With these
          details the csv export can now be the input to create invites for a shareholders’ meeting
          or a dividend invoice.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  /**
   * Release 2023-06-08
   */
  {
    date: parseISO('2023-06-08T15:00:00+0200'),
    title: 'History of the legal entity details',
    content: (
      <div>
        <p>
          It is now possible to save the history of a legal entity or shareholder and keep it
          visible in Evidend. On synchronising the kvk data, Evidend will notice if there are any
          changes and if so will allow to enter an effective date or upload an updated version of
          the Articles of Association.
        </p>
        <p>
          The updated data is saved in the history of the legal entity. The history can be accessed
          through the three dots in the upper right corner. The historical data can be viewed to
          make sure, for instance, that a revised name really belongs to the same shareholder.
        </p>
        <p>History of foreign legal entities will likewise be recorded.</p>
        <p>
          In the pdf export the history of both the company itself as well as that of the
          shareholders will be displayed.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2023-06-08T15:00:00+0200'),
    title: 'Effective date can be different from the date the is deed passed',
    content: (
      <div>
        <p>
          Up to now, in Evidend the effective date of a mutation was based on the deed passed date.
          Although this is in most cases correct, it is not necessarily the case. For mutations that
          are the effect of mergers or demergers the effective date is always the day after the deed
          is passed. Therefore, it is possible form now on for all mutations to enter an effective
          date separately from the deed passed date.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2023-06-08T15:00:00+0200'),
    title: 'Tags for parties',
    content: (
      <div>
        <p>
          On the party screen a function has been added to add tags to a party. In this way it is
          now possible to mark parties with special properties.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2023-06-08T15:00:00+0200'),
    title: 'Grouping for depositary receipt holders',
    content: (
      <div>
        <p>
          For users of “Evidend voor het Certificaathoudersregister” a more advanced system for
          classification has been added. For these users a screen has been added for grouping. On
          this screen it is possible to add all, or only a part of the depositary receipts of a
          depositary receipt holder to a specific group.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  /**
   * Release 2023-07-03
   */
  {
    date: parseISO('2023-07-03T17:00:00+0200'),
    title: 'Work in non-chronological order',
    content: (
      <div>
        <p>
          Up till now, it was only possible to work in Evidend in the chronological order. In this
          way it was quite hard to add a mutation that had been overlooked before or make a
          correction in a previous mutation.
        </p>
        <p>
          Now it is possible to add a new mutation at any point in time as long as it is after the
          initial mutation. Evidend will add it in the correct order between the existing mutations.
          If the new mutation has impact on a subsequent mutation causing the register to become
          incorrect, the offending mutation will be marked with “correction needed”. Thus you can
          quickly identify what needs correcting.
        </p>
        <p>
          Before, it was only possible to correct or delete the most recent mutation. From now on
          all mutations can be corrected or deleted. Evidend will then check all the subsequent
          mutations, and will indicate if they are no longer consistent.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2023-07-03T17:00:00+0200'),
    title: 'Amendment of articles of association',
    content: (
      <div>
        <p>
          Amendments of the articles of association can now be entered and retrieved in Evidend. For
          several mutations (e.g. conversion), it is possible to indicate that the underlying
          document is an amendment of the articles of association. Furthermore, a new mutation has
          been introduced for amendments of the articles of association that have no impact on the
          share capital.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2023-07-03T17:00:00+0200'),
    title: 'Groups for depositary receipt holders',
    content: (
      <div>
        <p>
          For the group functionality introduced in the previous release, there now is a csv export
          available. This opens up the grouping information for further processing.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2023-07-03T17:00:00+0200'),
    title: 'Pages for pledgees, usufructuaries and administrators',
    content: (
      <div>
        <p>
          Pages for pledgees, usufructuaries and administrators will now always be added to the pdf,
          even when they are empty. This will make it easier for the reader of the pdf to determine
          that there are no pledges, usufructs and administrations in the register.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2023-07-03T17:00:00+0200'),
    title: 'Small improvements',
    content: (
      <ul>
        <li>Better support for foreign addresses</li>
        <li>The predefined share types have made their return</li>
        <li>The legal form is now also translated when the English language has been selected</li>
        <li>The same share can be pledged more than once</li>
      </ul>
    ),
    media: image_default.src,
  },
  /**
   * Release 2023-08-09
   */
  {
    date: parseISO('2023-08-09T11:00:00+0200'),
    title: 'Retrieve the title of ownership quickly',
    content: (
      <div>
        <p>
          It is possible to upload the deed with each mutation. This is very useful during research
          for a next deed, but it is not always easy to find the relevant deed when there are a lot
          of mutations in the register.
        </p>
        <p>
          It is now possible from the shareholders tab to use the menu on the right of each share
          range to select “Title of Ownership”. Thus you are guided to the mutations that caused the
          current ownership of the range with details about the corresponding deed. Details about
          mutations such as pledge and usufruct can likewise be easily retrived by clicking the tag
          in the properties column.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2023-08-09T11:00:00+0200'),
    title: 'Inheritance',
    content: (
      <div>
        <p>
          Evidend did not provide a proper way to process the decease of a shareholder, and the
          subsequent transmission of their shares to the heirs. It is now possibnle to add a date of
          death for a natural person. In this way it becomes clear in the register that shares are
          no longer held by this person, but by their heirs.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2023-08-09T11:00:00+0200'),
    title: 'Depositary receipt holders with meeting rights',
    content: (
      <div>
        <p>
          There was not a convenient way yet to register depoistary receipt holders with meeting
          rights to the register of a BV. Evidend now allows you to add a party on the parties tab
          and add a start date for meeting rights to the party as well. In this way an overview of
          the meeting rights can be easily found on the parties tab. These depositary receipt
          holders with meeting rights will also appear on the pdf export.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2023-08-09T11:00:00+0200'),
    title: 'Trust conditions',
    content: (
      <div>
        <p>
          In the previous release the option to register updates in the articles of association was
          added to Evidend. For depositary receipt holders registers, we now also added the
          registration of updates of the trust conditions. This works in the same way as the updates
          for articles of association.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2023-08-09T11:00:00+0200'),
    title: 'Most recently viewed registers on the homepage',
    content: (
      <div>
        <p>
          On the dashboard on the Evidend homepage, a small number of registers is available for
          quick access. These used to be the first registers in the alphabetical order. We changed
          this to be the most recently viewed registers. In this way it is easier to find the
          registers that your office worked on the most recently.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2023-08-09T11:00:00+0200'),
    title: 'Small fixes and improvements',
    content: (
      <ul>
        <li>
          It is no longer required to specify a document when registering a paying-up mutation.
        </li>
        <li>
          When handing over a register to the board of the BV, the email address from the contact
          details is already prefilled.
        </li>
        <li>
          It is now possible to select a single pledge or ususfruct to terminate when there are
          multiple for the same share range.
        </li>
        <li>
          The service that allowed filling in a Dutch postal code and street number to retrieve a
          full address broke down last week. This is now fixed.
        </li>
      </ul>
    ),
    media: image_default.src,
  },
  /**
   * Release 2023-09-05
   */
  {
    date: parseISO('2023-09-05T16:00:00+0200'),
    title: 'Limitation on shared data',
    content: (
      <div>
        <p>
          When sharing a register, all information related to that register was shared. We have
          reduced this to the data that is directly related to the shareholders register.
        </p>
        <p>
          This means that uploaded documents that are not related to a mutation, contact details of
          the legal entity and personal data of natural persons are only available to administrators
          of the register.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2023-09-05T16:00:00+0200'),
    title: 'Small fixes and improvements',
    content: (
      <ul>
        <li>
          When completing certain mutations, it was sometimes possible to execute the mutation more
          than once by clicking “Save” repeatedly. This will now be prevented.
        </li>
        <li>
          In certain situations, it was not made clear that a Dutch postal code was recognized. When
          a Dutch postal code is entered, the address that could be retrieved will now always be
          shown.
        </li>
      </ul>
    ),
    media: image_default.src,
  },
  /**
   * Release 2023-11-06
   */
  {
    date: parseISO('2023-11-06T17:00:00+0200'),
    title: 'Sharing with a party that does not have an Evidend subscription yet',
    content: (
      <div>
        <p>
          In certain cases, you might want to share your register with a third party, for example a
          notary. The sharee might not have an Evidend license.
        </p>
        <p>
          It is now possible to share a register with parties without a paid subscription and allow
          them to register new mutations.
        </p>
        <p>
          The sharee can create a free account that enables them to access and update the shared
          register.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2023-11-06T17:00:00+0200'),
    title: 'Creating a free account',
    content: (
      <div>
        <p>
          If you share a register with someone who does not have an Evidend account yet, they will
          receive an email with instructions on how to create the account and an explanation about
          the possibilities and limitations.
        </p>
        <p>
          Logging in with the new account will immediately give access to the shared register. From
          then on, the account can be used to handle other shared registers as well.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2023-11-06T17:00:00+0200'),
    title: 'Changelog',
    content: (
      <div>
        <p>
          When a register is shared with different parties, it becomes more important to keep track
          of which updates were done by whom. For that purpose we added the changelog. It can be
          found for every register under the three dots in the upper right hand corner. The
          changelog is available for administrators of the register. It contains an overview of
          which updates have been done to the register, when they were done, and by whom.
        </p>
        <p>
          This overview is only available for administrators of the register, so it cannot be
          accessed by just anyone that the register is shared with.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  /**
   * Release 2023-11-20
   */
  {
    date: parseISO('2023-11-20T18:00:00+0200'),
    title: 'Temporary read-only access without an account',
    content: (
      <div>
        <p>
          In order to facilitate showing a register to a third party, it is now possible to view a
          register without creating an account. When you share a register with someone who does not
          have an account yet, and you choose to only give “Can View” rights, they will receive an
          email with a link to directly go to the register in Evidend. This link will expire after
          14 days.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2023-11-20T18:00:00+0200'),
    title: 'Limited display of the corporate structure',
    content: (
      <div>
        <p>
          The display of the corporate structure in Evidend can become quite extensive. This will
          give a complete overview, but it can be useful to zoom in to what is relevant for one
          entity. Therefore, it is now possible to use the display options in the lower righthand
          corner to switch between a full and a limited view. In the limited view, only the lines to
          shareholders and participations are included which reduces clutter and gives a clear
          overview of the currently selected entity.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  /**
   * Release 2023-12-14
   */
  {
    date: parseISO('2023-12-14T17:00:00+0200'),
    title: 'Changes to properties of shares',
    content: (
      <div>
        <p>
          Until now, it was not always easy to register changes for share types. Evidend did not
          offer a good way to manage share types. Because of that, for a small mistake while
          creating the share type it was sometimes necessary to completely redo the mutation it was
          created in. Changing the authorised capital was also not a very straightforward
          registration in Evidend.
        </p>
        <p>
          Therefore, we added the sharetypes tab to the register. Here you can find an overview of
          the sharetypes used in the register and, if they changed during the lifetime of the
          register, their different versions. Any corrections can be applied directly from this tab.
        </p>
        <p>
          Additionally, we added a step to the wizard for relevant mutations that allows you to
          register changes in the share type as part of that mutation. For example, when the
          authorized capital is increased and the new shares are issued, you can update the
          authorized capital as part of the “issuance” mutation (and, of course, you can still mark
          the mutation as resulting from a change in the artciles of association).
        </p>
        <p>
          Of course, these new features are also available for depoistary receipts in registers for
          a foundation.
        </p>
        <p>
          Watch the{' '}
          <a href="https://youtu.be/AaxLNoRdB3Q" target="_blank">
            instructional video
          </a>{' '}
          for this new addition
        </p>
      </div>
    ),
    media: image_default.src,
  },
  /**
   * Release 2023-12-22
   */
  {
    date: parseISO('2023-12-22T15:00:00+0200'),
    title: 'Changes in mutation costs',
    content: (
      <div>
        <p>
          From January 1st 2024 costs for mutations will be increased from 25 to 30 credits, or from
          10 to 12 credits for mutations that took place before January 1st 2022.
        </p>
        <p>
          Current credit balances will be increased by 20% to compensate for this price change. It
          is possible to buy credit bundles with a discount. You can contact your accountmanager or{' '}
          <a href="mailto:evidend@sdu.nl" target="_blank">
            evidend@sdu.nl
          </a>
          .
        </p>
      </div>
    ),
    media: (
      <table>
        <thead>
          <tr>
            <th>
              Credit bundle
              <br />
              (price per credit)
            </th>
            <th>Price</th>
            <th>Discount</th>
            <th>Price per mutation</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>100</td>
            <td>€ 100,00</td>
            <td>0%</td>
            <td>€ 30,00</td>
          </tr>
          <tr>
            <td>250</td>
            <td>€ 243,75</td>
            <td>2,5%</td>
            <td>€ 29,25</td>
          </tr>
          <tr>
            <td>500</td>
            <td>€ 475,00</td>
            <td>5%</td>
            <td>€ 28,50</td>
          </tr>
          <tr>
            <td>1.000</td>
            <td>€ 900,00</td>
            <td>10%</td>
            <td>€ 27,00</td>
          </tr>
          <tr>
            <td>1.500</td>
            <td>€ 1.312,50</td>
            <td>12,5%</td>
            <td>€ 26,25</td>
          </tr>
          <tr>
            <td>2.000</td>
            <td>€ 1.700,00</td>
            <td>15%</td>
            <td>€ 25,50</td>
          </tr>
        </tbody>
      </table>
    ),
  },
  {
    date: parseISO('2023-12-22T15:00:00+0200'),
    title: 'Sharing a register with viewing rights',
    content: (
      <div>
        <p>
          In a previous release we introduced temporary sharing of a register. This eliminated the
          option to permanently share a register with just viewing rights. Because there is also a
          need to share registers in that way we have now added the choice. Sharing with only
          viewing rights can now either be done temporarily for 14 days without the need for the
          sharee to create an account, or permanently which requires the sharee to create a (free)
          account.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  /**
   * Release 2024-02-05
   *
   */
  {
    date: parseISO('2024-02-05T17:00:00+0200'),
    title: 'Dividend overview',
    content: (
      <div>
        <p>
          For users with a subscription for Evidend Corporate Housekeeping or Evidend
          Certificaathoudersregister, it is now possible to create an overview of the dividends to
          be paid out based on the distribution of shares or depositary receipts. Click on the three
          dots in the upper righthand corner of a register and select “Dividend overview”.
        </p>
        <p>
          Now you can create a new overview by submitting some data such as the amount to be paid
          out per share or depositary receipt. Evidend then calculates the amount of dividend to be
          paid out to each party. Of course, dividend rights transferred to a usufructuary are taken
          into account.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2024-02-05T17:00:00+0200'),
    title: `Dividend Statements`,
    content: (
      <div>
        <p>
          It is also possible to create a csv export of the overview. Furthermore, you can also
          generate dividend statements in pdf format for each party in the overview.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  {
    date: parseISO('2024-02-05T17:00:00+0200'),
    title: `Notary unknown`,
    content: (
      <div>
        <p>
          Sometimes when entering historical mutations, it is no longer possible to know who the
          notary was that passed the deed. Evidend always enforced entering the data of the notary,
          so it was needed to enter “Unknown” for the name of the Notary. It is now possible to
          select “Notary unknown” when entering the data for the document, so that you can skip
          entering the notary details.
        </p>
      </div>
    ),
    media: image_default.src,
  },
  /**
   * Release 2024-09-12
   *
   */
  {
    date: parseISO('2024-09-12T17:00:00+0200'),
    title: 'Digital signing',
    content: (
      <div>
        <p>
          We have received multiple requests to provide for a way for the board to approve changes
          by their notary in the register. Until now, Evidend lacked an equivalent of the signature
          that was set in the paper register.
        </p>
        <p>
          In this update we have added the possibility to all registers to check and digitally sign.
          Evidend will show clearly if a register has been signed and if there have been changes
          since the last signing. Any mutations done since the last signing will be displayed.
        </p>
        <p>Evidend will keep a record when the register was signed and by whom.</p>
        <p>
          A short video is available on the support page with a more in-depth explanation about
          digital signing in Evidend.
        </p>
      </div>
    ),
    media: image_default.src,
  },
];
